import { Store, useStore } from 'appData/store'
import { Unsubscribe, User } from 'firebase/auth'
import { Timestamp } from 'firebase/firestore'
import AppLayout from 'layout/AppLayout'
import AddPost from 'pages/AddPostPage/AddPost'
import Denied from 'pages/Denied'
import FroalaEditor from 'pages/FroalaEditorPage/FroalaEditor'
import HomePage from 'pages/HomePage'
import PublicNews from 'pages/PublicNews/PublicNews'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Navigate, Route, Routes } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { getUser, gracefullyUpdate, promoteStagedUser } from 'service/controller/User'
import {
    auth,
    logAnalyticEvent,
    setAnalyticUserId,
    signInWithGoogle
} from 'service/firebase'
import { AcademySummaryLookup } from 'service/model/AcademySummaryModel'
import { UserModel } from 'service/model/User'
import { DeniedFunction } from 'store/constants/auth'
import AppContext, { ContextProps } from 'store/contexts/AppContext'

// import { GoogleAuth } from 'google-auth-library'
import { Box } from '@mui/material'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import Loader from 'pages/Loader'
import NewProfilePage from 'pages/ProfilePage/NewProfilePage'
import shallow from 'zustand/shallow'
import { PermissionsPage } from './pages/Permissions/PermissionsPage'

declare module '@mui/material/styles' {
    interface Theme {
        navBarWidth: number
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        navBarWidth?: number
    }
}

type RequireGoogleAuthProps = {
    children: JSX.Element
}

const storeSelector1 = (store: Store) => ({
    initialiseStore: store.initialiseStore,
    setCurrentUser: store.setCurrentUser
})
// export const invalidateStore = () => useStore.persist.clearStorage()

const RequireGoogleAuth: FC<RequireGoogleAuthProps> = ({ children }) => {
    const authCtx = useContext(AppContext)
    const { initialiseStore, setCurrentUser } = useStore(storeSelector1, shallow)

    const [loading, _error] = useAuthState(auth)

    // useEffect(() => {
    //     invalidateStore()
    // }, [])

    useEffect(() => {
        try {
            initialiseStore()
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Initialize store error')
        }
    }, [initialiseStore])

    useEffect(() => {
        const { currentUser, credentialToken } = authCtx.state.auth
        console.info('AUTH CURRENT USER => ', currentUser)
        setCurrentUser(currentUser || undefined, credentialToken || undefined)
    }, [setCurrentUser, authCtx.state.auth])

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return
        }

        if (authCtx.state.auth.token) {
            // eslint-disable-next-line no-console
            console.log('Appuserlog', authCtx.state.auth.currentUser)
        }
    }, [
        loading,
        authCtx.state.auth.token,
        authCtx.state.auth.currentUser,
        authCtx.state.auth.denied
    ])

    if (authCtx.state.auth.token) {
        return children
    }

    if (authCtx.state.auth.denied) {
        return <Denied />
    }

    return <Loader />
}

const processUser = async (
    incomingUser: User,
    adlm: AcademySummaryLookup,
    login: ContextProps['state']['auth']['login'],
    denied: DeniedFunction
) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    // const credential = GoogleAuthProvider.credentialFromResult(incomingUser)
    const idToken = await incomingUser.getIdToken() // also refreshes the token if less than five minutes to expiry
    // const idTokenResult = await incomingUser.getIdTokenResult() // also refreshes the token if less than five minutes to expiry
    // const credential = incomingUser.
    const accessToken = idToken
    const _googleUser = incomingUser

    console.info('_googleUser => ', _googleUser)

    if (_googleUser) {
        getUser(_googleUser.uid).then(user => {
            if (!user) {
                const userModel: UserModel = {
                    uid: _googleUser.uid as string,
                    name: _googleUser.displayName as string,
                    authProvider: 'google',
                    email: _googleUser.email as string,
                    domain:
                        _googleUser?.email?.indexOf('@') !== -1
                            ? _googleUser!.email?.split('@')[1]
                            : '',
                    jobFamilies: [],
                    academyCodes: [],
                    regions: [],
                    linkedEmails: [],
                    createdAt: Timestamp.now(),
                    displayName: _googleUser.displayName ?? undefined,
                    photoUrl: _googleUser.photoURL ?? undefined,
                    phoneNumber: _googleUser.phoneNumber ?? undefined,
                    lastLoginAt: _googleUser.metadata.lastSignInTime
                }
                promoteStagedUser(userModel, adlm).then(user2 => {
                    if (user2) {
                        login(_googleUser.uid, user2, accessToken || '')
                    } else {
                        denied()
                    }
                    /* if (!user2) {
                        setUser(userModel, userModel.uid).then(() => {
                            login(_googleUser.uid, userModel, accessToken || '')
                        })
                    } else {
                        login(_googleUser.uid, user2, accessToken || '')
                    } */
                })
            } else {
                const newUser = { ...user }
                newUser.displayName = _googleUser.displayName ?? undefined
                newUser.photoUrl = _googleUser.photoURL ?? undefined
                newUser.phoneNumber = _googleUser.phoneNumber ?? undefined
                newUser.lastLoginAt = _googleUser.metadata.lastSignInTime
                gracefullyUpdate(newUser, adlm).then(user2 => {
                    if (user2) {
                        login(_googleUser.uid, user2, accessToken || '')
                    } else {
                        denied()
                    }
                })
            }
        })
    }
}
const storeSelector2 = (store: Store) => ({
    academyDomainLookup: store.academyDomainLookup
})

export default function App() {
    // log(`▶▶▶ Entering App! - referer: '${document.referrer}'`)
    const { academyDomainLookup } = useStore(storeSelector2, shallow)
    const signingIn = useRef<boolean>()
    // const prevUserCredential = useRef<UserCredential | undefined | null>()

    const authCtx = useContext(AppContext)
    const authCtxStateAuthLogin = authCtx.state.auth.login
    const authCtxStateAuthDenied = authCtx.state.auth.deniedCallback
    const authCtxStateAuthToken = authCtx.state.auth.token

    const [isGoogleButtonVisible, setIsGoogleButtonVisible] = useState<boolean>(false)

    // const prevAdlm = useRef<typeof academyDomainLookup>()
    // const prevAuthCtxStateAuthLogin = useRef<typeof authCtxStateAuthLogin>()
    // const prevAuthCtxStateAuthToken = useRef<typeof authCtxStateAuthToken>()

    useEffect(() => {
        let unsubscribe: Unsubscribe | undefined

        const asyncHelper = async () => {
            unsubscribe = auth.onAuthStateChanged(async user => {
                if (!user) {
                    // if (!signingIn.current) {
                    //     signingIn.current = true
                    //     signInWithGoogle()
                    // }
                    setIsGoogleButtonVisible(true)
                } else {
                    signingIn.current = false
                    setIsGoogleButtonVisible(false)

                    // Analytic Events
                    if (user.uid) {
                        setAnalyticUserId(user.uid)
                    }
                    logAnalyticEvent('login', { method: 'Google' })

                    // const googleAuth = new GoogleAuth({
                    //     scopes: 'https://www.googleapis.com/auth/cloud-platform'
                    // })
                    // const client = await googleAuth.getClient()
                    // const projectId = await googleAuth.getProjectId()
                    // const url = `https://dns.googleapis.com/dns/v1/projects/${projectId}`
                    // const res = await client.request({ url })

                    await processUser(
                        user,
                        academyDomainLookup,
                        authCtxStateAuthLogin,
                        authCtxStateAuthDenied
                    )
                }
            })
        }
        asyncHelper()
        return unsubscribe
    }, [
        academyDomainLookup,
        authCtxStateAuthLogin,
        authCtxStateAuthToken,
        authCtxStateAuthDenied
    ])

    const renderGoogleOAuthButton = (
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            height='100vh'
        >
            <p style={{ color: 'grey', fontSize: '14px' }}>
                Please click below to access the Intranet:
            </p>
            <br />
            <GoogleLogin
                onSuccess={(credentialResponse: any) => {
                    signInWithGoogle(credentialResponse)
                }}
                onError={() => {
                    console.error('Login Failed')
                }}
            />
        </Box>
    )

    const renderApp = (
        <RequireGoogleAuth>
            <AppLayout headerHeight={75}>
                <Routes>
                    <Route path='/fro' element={<FroalaEditor />} />
                    <Route path='/AddPost/:id' element={<AddPost />} />
                    <Route path='/News/:id' element={<PublicNews />} />
                    <Route path='/AddPost' element={<AddPost />} />
                    <Route path='profile' element={<NewProfilePage />} />
                    <Route path='permissions' element={<PermissionsPage />} />
                    <Route
                        path='/*'
                        element={
                            /* 
                            <Routes>
                                <Route index element={ */ <HomePage /> /* } />
                            </Routes> */
                        }
                    />
                    <Route path='*' element={<Navigate to='/' />} />
                    <Route path='/:id' element={<HomePage />} />
                </Routes>
            </AppLayout>
        </RequireGoogleAuth>
    )

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
            {isGoogleButtonVisible ? renderGoogleOAuthButton : renderApp}
        </GoogleOAuthProvider>
    )
}
